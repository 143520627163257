<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null">
          <v-card-title>
            Customers
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="customers"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <b-card class="mt-3" header="Result">
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group id="input-group-2" label="Name:" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="selected.name"
                  required
                  placeholder="Enter name"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-1" label="Last Name:" label-for="input-3">
                <b-form-input id="input-3" v-model="selected.last_name" type="text" required></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-1" label="Email address:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="selected.email"
                  type="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-button
                type="button"
                @click="selected = null"
                variant="danger"
                style="margin-right: 20px;"
              >Return</b-button>
              <b-button type="button" variant="primary">Save</b-button>
            </b-form>
          </b-card>

          <b-card class="mt-3" header="Homes Info" v-if="selected.home">
            <div>
              <b-table striped hover :items="selected.home" :fields="fields"></b-table>
            </div>
          </b-card>

          <b-card class="mt-3" header="Offices Info" v-if="selected.office">
            <div>
              <b-table striped hover :items="selected.office" :fields="fields"></b-table>
            </div>
          </b-card>

          

          <v-card v-if="orders" style="margin-top: 20px;">
            <v-card-title>
              Payment History
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchOrders"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headerOrders"
              :items="orders"
              :search="search"
            ></v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "customer-list",
  methods: {
    clicked(value) {

      this.selected = value;
      this.getOrders(this.selected.id)
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    getOrders(id){
      fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readOrderCustomer&id="+id)
        .then(res => res.json())
        .then(data => {
          this.orders = data.orders
          console.log(this.orders)
        })
        .catch(console.log);
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  data() {
    return {
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn"
      ],
      search: "",
      searchOrders: "",
      selected: null,
      orders: null,
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" }
      ],
      headerOrders: [
        { text: "Id", value: "id" },
        { text: "Status", value: "status" },
        { text: "Type Cleaning", value: "type" },
        { text: "Cleaning Date", value: "date" },
        { text: "Purchase Date", value: "created_at" },
        { text: "Payment Type", value: "payment_type" },
        { text: "Value", value: "value" }
      ],
      customers: [
        {
          id: "",
          name: "",
          last_name: "",
          email: ""
        }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readCustom")
      .then(res => res.json())
      .then(data => {
        this.customers = data.customers;
        console.log(this.customers);
      })
      .catch(console.log);
  }
};
</script>
